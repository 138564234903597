<template>
  <TabsComponent />
</template>

<script>
import TabsComponent from './components/TabsComponent.vue'

export default {
  name: 'CustomerCreatePage',
  components: {
    TabsComponent,
  },
}
</script>
